import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
  path: '/',
  name: 'index',
  component: () => import('../views/index.vue')
},
{
  path: '/page2',
  name: 'page2',
  component: () => import('../views/page2.vue')
},{
  path: '/page3',
  name: 'page3',
  component: () => import('../views/page3.vue')
},
{
  path: '/page4',
  name: 'page4',
  component: () => import('../views/page4.vue')
},
{
  path: "*",			// 匹配用户访问的请求
  redirect: "/"		// 将用户的请求重新定向到另一个请求
},
]
const router = new VueRouter({
  routes
})

export default router